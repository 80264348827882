<common-confirm></common-confirm>

<h1 class="container-heading">Add label</h1>
<div [formGroup]="formGroup">
    <mat-card class="mat-elevation-z2">
        <h2>
            1. Check shipment info
        </h2>

        <div class="flex space-between row">
            <div class="half">
                <mat-card>
                    <div class="title"><b>Address From</b></div>
                    <br>
                    <form [formGroup]="fromAddress" *ngIf="fromAddress">
                        <div class="group flex">
                            <ng-container *ngFor="let control of fromAddress.controls | keys">
                                <mat-form-field *ngIf="control !=='in_eu'" class="full">
                                    <mat-label>{{ control | ucfirst }}</mat-label>
                                    <input matInput type="text" [formControlName]="control">
                                </mat-form-field>
                            </ng-container>
                        </div>

                        <div class="group flex" *ngIf="shipment">
                            <ng-container *ngFor="let control of fromAddress.controls | keys">
                                <div class="full padding-bottom-10 padding-left-10 padding-right-10 padding-top-10 background-danger margin-bottom-10"
                                     *ngIf="fromAddress.controls[control].errors">
                                    <b>{{ control | ucfirst }}</b> field
                                    <span *ngIf="fromAddress.controls[control].errors?.['required']">
                                        is required.
                                    </span>

                                    <span *ngIf="fromAddress.controls[control].errors?.['maxlength']">
                                        max length {{ fromAddress.controls[control].errors?.['maxlength']['requiredLength'] }}
                                        .
                                    </span>

                                    <span *ngIf="control === 'phone' && fromAddress.controls[control].errors?.['pattern']">
                                        can contain +-() and numbers only.
                                    </span>

                                    <span *ngIf="control === 'email' && fromAddress.controls[control].errors?.['pattern']">
                                        must be a valid email address.
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </form>
                </mat-card>
            </div>
            <div class="half">
                <mat-card>
                    <div class="title"><b>Address To</b></div>
                    <br>
                    <form [formGroup]="toAddress" *ngIf="toAddress">
                        <div class="group flex">
                            <ng-container *ngFor="let control of toAddress.controls | keys">
                                <mat-form-field class="full" *ngIf="control !=='in_eu'">
                                    <mat-label>{{ control | ucfirst }}</mat-label>
                                    <input matInput type="text" [formControlName]="control">
                                </mat-form-field>
                            </ng-container>
                        </div>

                        <div class="group flex" *ngIf="shipment">
                            <ng-container *ngFor="let control of toAddress.controls | keys">
                                <div class="full padding-bottom-10 padding-left-10 padding-right-10 padding-top-10 background-danger"
                                     *ngIf="toAddress.controls[control].errors">
                                    {{ control | ucfirst }} field
                                    <span *ngIf="toAddress.controls[control].errors?.['required']">
                                        is required.
                                    </span>

                                    <span *ngIf="toAddress.controls[control].errors?.['maxlength']">
                                        max length {{ toAddress.controls[control].errors?.['maxlength']['requiredLength'] }}
                                        .
                                    </span>

                                    <span *ngIf="control === 'phone' && toAddress.controls[control].errors?.['pattern']">
                                         can contain only + - ( ) and numbers.
                                    </span>

                                    <span *ngIf="control === 'email' && toAddress.controls[control].errors?.['pattern']">
                                        must be a valid email address.
                                    </span>
                                </div>
                            </ng-container>
                        </div>

                    </form>
                </mat-card>
            </div>
        </div>
    </mat-card>

    <mat-card>
        <h2>
            2. Choose packages
        </h2>

        <div style="width:600px; margin: 0 auto" class="padding-top-20">
            <h2>
                Select packages:
            </h2>

            <mat-card *ngFor="let pack of packages" class="margin-bottom-10">
                <div>
                    <mat-checkbox value="{{pack.id}}" (change)="onCheckChange($event)"
                                  [checked]="selected_packages.includes(pack.id)">
                        {{ pack.name }}
                        [
                        {{ pack.height }}x{{ pack.width }}x{{ pack.length }}{{ pack.distance_unit }}
                        , {{ pack.weight > pack.gross_weight ? pack.weight : pack.gross_weight }}
                        {{ pack.weight > pack.gross_weight ? pack.mass_unit : pack.gross_unit }}
                        ]
                    </mat-checkbox>
                    <mat-icon class="pull-right pointer" aria-hidden="true" (click)="editPackage(pack)">create
                    </mat-icon>
                </div>
            </mat-card>

            <div class="actions">
                <button type="button" mat-raised-button (click)="newPackage()" color="accent">
                    Create package
                </button>
            </div>

        </div>

        <div class="padding-top-20" *ngIf="needs_customs_info">
            <h2>
                Customs info:
            </h2>

            <div>
                <table class="table customs-table">
                    <tr>
                        <th>#</th>
                        <th>Desc</th>
                        <th>Weight</th>
                        <th>Qty</th>
                        <th>Unit value</th>
                        <th>Origin</th>
                        <th>Currency</th>
                        <th>HS Code</th>
                        <th></th>
                    </tr>
                    <ng-container formArrayName="customs_info">
                        <ng-container *ngFor="let customs_item of customs_info.controls; let i = index">
                            <tr [formGroup]="customs_item">
                                <td class="text-center">
                                    {{ i + 1 }}
                                </td>
                                <td>
                                    <mat-form-field>
                                        <input matInput formControlName="Description">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <input matInput formControlName="Weight">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <input matInput formControlName="Quantity">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <input matInput formControlName="UnitValue">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <mat-select formControlName="OriginCountry">
                                            <mat-option *ngFor="let country of countries"
                                                        [value]="country.country_iso_2">
                                                {{ country.country_name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <mat-select formControlName="Currency">
                                            <mat-option value="USD">USD</mat-option>
                                            <mat-option value="EUR">EUR</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <input matInput formControlName="HSCode">
                                    </mat-form-field>
                                </td>
                                <td class="text-center">
                                    <mat-icon (click)="deleteItem(i)" class="pointer color-warn">delete</mat-icon>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <tr>
                        <td colspan="9" class="text-right">
                            <button mat-raised-button type="button" color="accent" (click)="addItem()">
                                <mat-icon>add</mat-icon>
                                Add item
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </mat-card>

    <mat-card>
        <h2>
            3. Choose service level
        </h2>

        <form [formGroup]="formGroup">
            <ng-container *ngIf="courier_service_name">
                <div>
                    <h2>Order details:</h2>
                </div>
                <div class="group flex">
                    <div class="half">
                        <b>Courier service:</b> {{ courier_service_name }}
                    </div>
                </div>
            </ng-container>

            <div>
                <h2>Customer account:</h2>
            </div>
            <div class="group flex">
                <div class="half" *ngIf="customer_account_courier_company">
                    <b>Courier company:</b> {{ customer_account_courier_company }}
                </div>
                <div class="half" *ngIf="service_level_name">
                    <b>Service level:</b> {{ service_level_name }}
                </div>
            </div>
            <div class="group flex">

                <mat-form-field class="half">
                    <mat-label>Content description</mat-label>
                    <input matInput formControlName="content_description">
                </mat-form-field>

                <mat-form-field class="quarter">
                    <mat-label>Total value</mat-label>
                    <input type="number" step="0.01" matInput formControlName="total_value">
                </mat-form-field>

                <mat-form-field class="quarter">
                    <mat-label>Currency</mat-label>
                    <mat-select formControlName="total_value_currency">
                        <mat-option value="USD">USD</mat-option>
                        <mat-option value="EUR">EUR</mat-option>
                        <mat-option value="GBP">GBP</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Incoterm</mat-label>
                    <mat-select formControlName="incoterm">
                        <mat-option value="DAP">DAP</mat-option>
                        <mat-option value="DDP">DDP</mat-option>
                        <mat-option value="EXW">EXW</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Bill account number (EXW only)</mat-label>
                    <input type="text" matInput formControlName="bill_account_number">
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Notes</mat-label>
                    <input matInput formControlName="note">
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Send label to</mat-label>
                    <mat-chip-grid #chipGrid aria-label="Fruit selection">
                        <mat-chip-row *ngFor="let email of formGroup.value.send_to" (removed)="removeEmail(email)">
                            {{ email }}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input placeholder="Email" #userInput [formControl]="userCtrl"
                           [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           (matChipInputTokenEnd)="addEmail($event)"/>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="userSelected($event)">
                        <mat-option *ngFor="let user of filteredUsers | async" [value]="user.email">
                            {{ user.name }} ({{ user.email }})
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>


                <div class="full">
                    <mat-checkbox formControlName="add_insurance"
                                  [disabled]="number(formGroup.value.total_value) === 0">
                        Apply courier insurance
                        <small *ngIf="number(formGroup.value.total_value) === 0"
                               class="text-error">(no insurance for goods with value 0)</small>
                    </mat-checkbox>
                </div>

            </div>
        </form>

        <div style="width:600px; margin: 0 auto; padding: 20px 10px 0;">

            <div class="margin-bottom-20">

                <div class="pull-right">
                    <mat-checkbox [formControl]="showHiddenRates">Show hidden services</mat-checkbox>
                </div>

                <button type="button" mat-raised-button color="accent" class="main"
                        [disabled]="!can_get_rates || get_rates_in_progress"
                        (click)="getRates(formGroup.value)">
                    Get services
                </button>
            </div>


            <div>
                <h2>Available services:</h2>
            </div>

            <div>
                <mat-accordion multi>
                    <mat-expansion-panel *ngFor="let group of grouped_rates_to_list | async">
                        <mat-expansion-panel-header>
                            {{ group.group_name }} ({{ group.rates.length }})
                        </mat-expansion-panel-header>

                        <div *ngFor="let rate of group.rates" class="margin-bottom-10">
                            <mat-card (click)="selectCarrier(rate)"
                                      class="pointer mat-badge-inset"
                                      matBadge="Selected"
                                      matBadgeColor="accent"
                                      matBadgePosition="before"
                                      [matBadgeHidden]="formGroup.value.rate_id !== rate.rate_id"
                                      [class.mat-elevation-z5]="formGroup.value.rate_id === rate.rate_id">
                                <mat-card-header [class.text-disabled]="rate.message">
                                    <div mat-card-avatar [style.background-image]="'url('+rate.icon_url+')'"
                                         style="background-position:  50% 50%;background-size: contain; border-radius: 0;">
                                    </div>
                                    <mat-card-title>
                                        <b>{{ rate.carrier_label }}</b>
                                    </mat-card-title>
                                    <mat-card-title *ngIf="rate.service">
                                        <u>{{ rate.carrier }} -  {{ rate.service }}</u>
                                    </mat-card-title>
                                    <ng-container *userAllowed="['show_courier_service_price_rates']">
                                        <mat-card-subtitle style="margin-bottom: 0" *ngIf="rate.rate">
                                            Rate: {{ rate.rate }} {{ rate.currency }}
                                        </mat-card-subtitle>
                                    </ng-container>
                                    <mat-card-subtitle style="margin-bottom: 0" *ngIf="rate.delivery_days">
                                        {{ rate.delivery_days }} days to deliver
                                    </mat-card-subtitle>
                                    <mat-card-subtitle style="margin-bottom: 0;" class="text-error"
                                                       *ngIf="rate.message">
                                        {{ rate.message }}
                                    </mat-card-subtitle>
                                </mat-card-header>
                            </mat-card>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div *ngIf="!get_rates_in_progress && no_rates_to_list">
                No rates available, or form was changed. Please click on Get rates button to see available rates
            </div>
            <div *ngIf="get_rates_in_progress">
                <common-simple-spinner>Searching available services...</common-simple-spinner>
            </div>
        </div>

    </mat-card>

    <mat-card>
        <h2>
            4. Choose carrier options
        </h2>
        <div class="form" style="margin-bottom: 40px">
            <div class="margin-bottom-20">
                <h2>
                    Upload Paperless Documentation:
                </h2>
            </div>
            <div>
                <div class="upload-area"
                     *ngIf="!uploading"
                     (click)="chooseFile()"
                     (dragover)="dragOver($event)"
                     (drop)="fileDrop($event)">
                    <div *ngIf="!selected_file_name">
                        <mat-icon>add</mat-icon>
                        Drop file here or click to choose file from your computer
                        <br>
                        <small>
                            <i>
                                Only *.pdf files are accepted
                            </i>
                        </small>
                    </div>
                    <div *ngIf="selected_file_name">
                        Selected file: <b>{{ selected_file_name }}</b>
                    </div>
                </div>
                <div *ngIf="uploading">
                    <common-simple-spinner>Uploading file...</common-simple-spinner>
                </div>
            </div>
            <input type="file" #inputFile accept="application/pdf"
                   style="display: none;" (change)="fileChange($event)">

            <div class="padding-top-20">
                <button mat-raised-button type="button" class="main" color="accent"
                        [disabled]="!selected_file_data" (click)="submitFile()">
                    Send file
                </button>
                &nbsp;
                <button mat-raised-button *ngIf="selected_file_data" class="text-center" color="warn"
                        (click)="selected_file_data = null; selected_file_name = null">
                    <mat-icon class="margin-right-0">close</mat-icon>
                </button>

            </div>

        </div>

        <div class="form" *ngIf="carrier_options_fields_list && carrier_options_fields_list.length">
            <div class="margin-bottom-20">
                <h2>
                    Select carrier options:
                </h2>
            </div>

            <div class="form carrier-options">
                <common-form-fields [fields]="carrier_options_fields_list"
                                    (valueChange)="setCarrierOptions($event)"></common-form-fields>
            </div>

        </div>
        <div class="form" *ngIf="carrier_options_fields_list && !carrier_options_fields_list.length">
            <p>This carrier has no additional options</p>
        </div>
    </mat-card>
</div>

<mat-card>
    <div class="actions clear padding-top-20">
        <button [disabled]="!formGroup.valid" (click)="finish()" mat-raised-button color="primary" type="button"
                class="main">
            Finish
        </button>
    </div>
</mat-card>