import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {Form} from "../../../../../../../../common/interfaces/form.interface";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {MatChipInputEvent} from "@angular/material/chips";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Modal} from "../../../../../../../services/modal.service";
import ISelectOption = Form.ISelectOption;


@Component({
    selector: "section-warehouse-buffer-scan-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class WarehouseBufferScanFormComponent implements OnInit, OnDestroy, AfterViewInit {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private batchesMaxQty: { [key: string]: number } = {};

    private focusTimer: any = null;

    public modal: Modal.IModal;

    public submitInProgress: boolean = false;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("serialsField", {static: false})
    public serialsField: ElementRef;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = this.formBuilder.group({
        order_id: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        part_master_id: new FormControl(null, [Validators.required]),
        batch: new FormControl(null),
        serials: new FormControl([]),
        quantity: new FormControl(null, [Validators.required, Validators.min(1)]),
        order_item_id: new FormControl(null),
        shipment_id: new FormControl(null, [Validators.required]),
        warehouse_order_id: new FormControl(null, [Validators.required]),
        location_id: new FormControl(null),
        package_id: new FormControl(null),
        pallet_id: new FormControl(null),
        box_id: new FormControl(null),
        configurations: new FormControl(null),
        rev: new FormControl(null),
        lot: new FormControl(null),
        inner_boxes: new FormControl(null),
        wh_ref: new FormControl(null),
    });

    public serials: string[] = [];

    public needQty: number;

    public maxQty: number;

    public requiredSerialInfo: string = null;

    public availableLocations: Warehouse.ILocation[];

    public availableBatchOptions: ISelectOption[];

    public batchMaxQty: number = -1;

    public cursorField: any = null;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService,
        private renderer: Renderer2
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {

        this.formGroup.patchValue(this.modal.params.data);

        this.maxQty = Number(this.modal.params.data.quantity);

        if (this.modal.params.data.serial_required) {
            this.formGroup.get("serials").setValidators([Validators.required]);
            this.formGroup.get("serials").updateValueAndValidity();
        }

        if (this.modal.params.data.revision_required) {
            this.formGroup.get("rev").setValidators([Validators.required]);
            this.formGroup.get("rev").updateValueAndValidity();
        }

        this.formGroup.get("quantity").setValidators([Validators.required, Validators.max(this.maxQty)]);
        this.formGroup.get("quantity").setValue(null);
        this.formGroup.get("quantity").updateValueAndValidity();

        if (this.modal.params.data.serials) {
            this.serials = this.modal.params.data.serials;
            this.formGroup.get("quantity").setValue(this.serials.length);
        }


        this.changeDetectorRef.markForCheck();
    }

    private quantitySubscribe(): void {
        this.formGroup.get("quantity").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                if (this.serials.length > 0 && value !== "" + this.serials.length) {
                    this.formGroup.get("quantity").setValue(this.serials.length);
                }
            });
    }

    private prepareListeners(): void {
        const m_data: any = this.modal.params.data;
        if (
            this.modal.params.type === "outbound"
            && m_data && m_data.part_master && m_data.part_master.required_batch_outbound
        ) {
            this.formGroup.get("location_id").valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((location_id: number): void => {
                    if (location_id) {
                        this.getAvailableBatches(location_id);
                    }
                });

            this.formGroup.get("batch").valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((batch: string): void => {
                    this.batchMaxQty = this.batchesMaxQty[batch];
                    this.changeDetectorRef.markForCheck();

                    this.checkSelectedBatch(batch);
                });
        }
    }

    private async checkSelectedBatch(batch: string): Promise<any> {
        if (this.modal.params.data && this.modal.params.data.batch !== batch) {
            if (!await this.confirmRef.confirm(
                "You've selected batch [" + batch + "] instead of batch ["
                + this.modal.params.data.batch + "] in order " + this.modal.params.data.ref
            )) {
                this.formGroup.get("batch").setValue(this.modal.params.data.batch);
            }
        }
    }

    private getAvailableBatches(location_id: number): void {
        const selectedLocation: Warehouse.ILocation =
            this.availableLocations.find((location: Warehouse.ILocation): boolean => location.id === location_id);

        if (!selectedLocation) {
            return;
        }

        this.availableBatchOptions = [];

        const batches: string[] = [];
        for (const inventory of selectedLocation.inventories) {
            const b: string = inventory.batch ? inventory.batch : "-- no-batch --";
            batches.push(b);

            if (this.batchesMaxQty.hasOwnProperty(b)) {
                this.batchesMaxQty[b] += inventory.quantity;
            } else {
                this.batchesMaxQty[b] = inventory.quantity;
            }
        }

        const uniqueBatches: string[] = batches.filter((val: string): boolean => !!val)
            .filter((val: string, index: number, self: string[]): boolean => {
                return self.indexOf(val) === index;
            });

        this.availableBatchOptions = uniqueBatches.map((batch: string): ISelectOption => {
            return {value: batch, name: batch};
        });

        this.changeDetectorRef.markForCheck();
    }

    public async submitInBuffer(): Promise<any> {
        this.submitInProgress = true;
        this.spinnerService.show();
        this.changeDetectorRef.markForCheck();

        const body: any = {
            ...this.formGroup.value,
            batch: this.formGroup.value.batch === "-- no-batch --" ? null : this.formGroup.value.batch,
        };

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `${this.modal.params.state.section}/scan/${this.modal.params.type}/transaction`, body);

        this.submitInProgress = false;
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();

        if (data) {
            this.modal.response.next({
                name: "value",
                value: body
            });
        }

    }

    /**
     * Add serial chip
     * @param {MatChipInputEvent} event
     */
    public addSerial(event: MatChipInputEvent): void {
        const input: any = event.input;
        const values: string[] = event.value.split(/[\s,\t]+/);

        for (let value of values) {
            value = (value || "").trim().replace(/^\([0-9]{2}\)|^_+|_+$/g, "");

            if (value && this.serials.indexOf(value) === -1 && value !== this.modal.params.data.item) {
                this.serials.push(value.trim());
                this.formGroup.get("quantity").setValue(this.serials.length);
                this.formGroup.get("serials").setValue(this.serials);
            }
        }
        if (input) {
            input.value = "";
        }
    }

    /**
     * Remove serial chip
     * @param {string} serial
     */
    public removeSerial(serial: string): void {
        const index: number = this.serials.indexOf(serial);

        if (index >= 0) {
            this.serials.splice(index, 1);
            this.formGroup.get("quantity").setValue(this.serials.length > 0 ? this.serials.length : 1);
            this.formGroup.get("serials").setValue(this.serials);
        }
    }

    public makeScanOfFullPallet(): void {
        this.modal.response.emit({
            name: "scan_pallet",
            value: this.modal.params.data.pallet_ref
        });
    }

    public makeScanOfFullBox(): void {
        this.modal.response.emit({
            name: "scan_box",
            value: this.modal.params.data.box_ref
        });
    }

    public setCursorTo(field: any): void {
        this.cursorField = field;

        if (this.focusTimer) {
            clearTimeout(this.focusTimer);
        }

        if (!this.cursorField) {
            return;
        }
        this.focusTimer = setInterval(() => {
            this.renderer.selectRootElement(this.cursorField).focus();
        }, 500);

    }

    public ngOnInit(): void {
        this.formGroup.updateValueAndValidity();

        this.prepareForm();
        this.quantitySubscribe();
        this.prepareListeners();
    }

    public ngAfterViewInit(): void {
        setTimeout((): void => {
            if (this.serialsField) {
                this.setCursorTo(this.serialsField.nativeElement);
            }
        }, 1000);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();

        if (this.focusTimer) {
            clearTimeout(this.focusTimer);
        }
    }

}
