import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {Form} from "src/modules/common/interfaces/form.interface";
import {takeUntil} from "rxjs/operators";
import {HelpersService} from "src/modules/common/services/helpers.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";
import {Courier} from "src/modules/common/interfaces/courier.interface";

interface Params {
    name: string;
    label: string;
    input_type: string;
    default_value: any;
    required: boolean;
    options?: Form.ISelectOption[];
}

interface EntityParams {
    event: string;
    params: Params[];
}

@Component({
    selector: "section-auto-followups-form",
    styleUrls: ["form.component.scss"],
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AutoFollowupFormComponent implements OnInit, Base.IComponent, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        service_levels: new FormControl([], [Validators.required]),
        request_kind_id: new FormControl(null, [Validators.required]),
        team_id: new FormControl(null, [Validators.required]),
        order_remark_type_id: new FormControl(null, [Validators.required]),
        title: new FormControl(null, [Validators.required]),
        event: new FormControl(null, [Validators.required]),
        countries: new FormControl([]),
        customers: new FormControl([]),
        courier_services: new FormControl([]),
        send_email: new FormControl(false),
        tasks: new UntypedFormArray([]),
        params: new FormGroup({})
    });

    public readonly state: Base.IState;

    public events: Form.ISelectOption[] = [
        {value: "App\\Events\\order\\OrderCreated", name: "Order created"},
        // {value: "App\\Events\\order\\types\\replenishmentOrder", name: "Replenishment order confirmed"},
        // {value: "App\\Events\\order\\types\\transferOrder", name: "Transfer order confirmed"},
        // {value: "App\\Events\\order\\types\\salesOrder", name: "Sales order confirmed"},
        // {value: "App\\Events\\order\\types\\rmaOrder", name: "Rma order confirmed"},
        // {value: "App\\Events\\order\\types\\regularRmaOrder", name: "Regular rma order confirmed"},
        {value: "App\\Events\\order\\OrderConfirmed", name: "Order confirmed"},
        {
            value: "App\\Events\\threepl\\Transactions\\NewCourierTransactionReceived",
            name: "New courier transaction received"
        },
        {value: "App\\Events\\order\\PartialInbound", name: "Partial inbound order"},
        {value: "App\\Events\\InboundOrderItemsGroupClosed", name: "All items inbounded"},
        {value: "App\\Events\\OutboundOrderItemsGroupClosed", name: "All items outbounded"},
        {value: "OrderInboundWarningCheck", name: "Inbound not completed"},
        {value: "OrderPacketDueTime", name: "Packed due time"},
        {value: "AWBNotDelivered", name: "AWB not delivered"},
        {value: "TrackingCustomsDelay", name: "AWB Customs Delay"},
        {value: "ReleaseDocumentsAvailable", name: "Release documents is available"}
    ];

    public closeTaskEvents: Form.ISelectOption[] = [
        {value: "AWBAdded", name: "AWB Added"},
        {value: "AWBDelivered", name: "AWB Delivered"},
        {value: "OrderStatusChanged", name: "Order status changed"}
    ];

    public eventParams: EntityParams[] = [
        {
            event: "AWBNotDelivered",
            params: [
                {
                    name: "delivered_days",
                    label: "Delivered days",
                    input_type: "number",
                    default_value: 7,
                    required: true,
                },
                {
                    name: "shipment_type",
                    label: "Shipment Type",
                    input_type: "dropdown",
                    default_value: "both",
                    required: true,
                    options: [
                        {
                            name: "Both",
                            value: "both"
                        },
                        {
                            name: "Inbound",
                            value: "inbound"
                        },
                        {
                            name: "Outbound",
                            value: "outbound"
                        }
                    ]
                }
            ]
        },
        {
            event: "OrderInboundWarningCheck",
            params: [
                {
                    name: "auto_close",
                    label: "Close Automatically on inbound complete",
                    input_type: "checkbox",
                    default_value: true,
                    required: false,
                }
            ]
        },
        {
            event: "OrderPacketDueTime",
            params: [
                {
                    name: "due_hours",
                    label: "Due hours",
                    input_type: "number",
                    default_value: 48,
                    required: false,
                },
                {
                    name: "auto_close",
                    label: "Close Automatically on order packed",
                    input_type: "checkbox",
                    default_value: true,
                    required: false,
                }
            ]
        },
        {
            event: "App\\Events\\threepl\\Transactions\\NewCourierTransactionReceived",
            params: [
                {
                    name: "shipment_type",
                    label: "Shipment Type",
                    input_type: "dropdown",
                    default_value: "both",
                    required: true,
                    options: [
                        {
                            name: "Both",
                            value: "both"
                        },
                        {
                            name: "Inbound",
                            value: "inbound"
                        },
                        {
                            name: "Outbound",
                            value: "outbound"
                        }
                    ]
                }
            ]
        }
    ];

    public taskParams: EntityParams[] = [
        {
            event: "AWBAdded",
            params: [
                {
                    name: "shipment_type",
                    label: "Shipment Type",
                    input_type: "dropdown",
                    default_value: "both",
                    required: true,
                    options: [
                        {
                            name: "Both",
                            value: "both"
                        },
                        {
                            name: "Inbound",
                            value: "inbound"
                        },
                        {
                            name: "Outbound",
                            value: "outbound"
                        }
                    ]
                }
            ]
        },
        {
            event: "AWBDelivered",
            params: [
                {
                    name: "shipment_type",
                    label: "Shipment Type",
                    input_type: "dropdown",
                    default_value: "both",
                    required: true,
                    options: [
                        {
                            name: "Both",
                            value: "both"
                        },
                        {
                            name: "Inbound",
                            value: "inbound"
                        },
                        {
                            name: "Outbound",
                            value: "outbound"
                        }
                    ]
                }
            ]
        },
        {
            event: "OrderStatusChanged",
            params: [
                {
                    name: "order_status_close",
                    label: "Status for close",
                    input_type: "dropdown",
                    default_value: null,
                    required: true,
                    options: []
                }
            ]
        }
    ]

    public currentEventParams: EntityParams;

    public remarkTypes: Order.IRemarkType[] = [];

    public teams: Form.ISelectOption[] = [];

    public kinds: Form.ISelectOption[] = [];

    public serviceLevels: { [key: string]: Order.IServiceLevel[] };

    public countries: Form.ISelectOption[] = [];

    public countriesFiltered: Form.ISelectOption[] = [];

    public countriesSearch: FormControl = new FormControl(null);

    public customers: Form.ISelectOption[] = [];

    public customersFiltered: Form.ISelectOption[] = [];

    public customersSearch: FormControl = new FormControl(null);

    public couriersServiceLevel: Form.ISelectOption[] = [];

    public couriersServiceLevelFiltered: Form.ISelectOption[] = [];

    public couriersServiceLevelSearch: FormControl = new FormControl(null);

    public eventStatuses: Form.ISelectOption[] = [];

    public taskDeadlineOptions = [
        -30, -15, 0, 15, 30, 45, 60, 75, 90
    ];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private router: Router,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private helperService: HelpersService,
    ) {
    }

    /**
     * Get contact data
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["auto-followups", this.state.params.id], {}, {
                relations: ["ServiceLevels:id", "CourierServices:id", "Customers:id"]
            });
        if (data) {
            if (data.tasks && data.tasks.length) {
                for (const i of data.tasks) {
                    let taskForm = this.addTask();
                    this.addTaskEventParams(taskForm, i.event_close, i.params);
                    if (i.event_close === "OrderStatusChanged"
                        && i.params?.order_status_close === "None") {
                            i.params.order_status_close = null;
                    }
                }
            }
            if (data.params && Object.keys(data.params).length) {
                this.addEventParams(data.event, data.params);
            }
            this.formGroup.patchValue(data);

            if (data.service_levels && data.service_levels.length) {
                this.formGroup.get("service_levels")
                    .setValue(data.service_levels.map((sl: Order.IServiceLevel): number => sl.id));
            }

            if (data.courier_services && data.courier_services.length) {
                this.formGroup.get("courier_services")
                    .setValue(data.courier_services.map((sl: Courier.IService): number => sl.id));
            }

            if (data.customers && data.customers.length) {
                this.formGroup.get("customers")
                    .setValue(data.customers.map((c: any): number => c.id));
            }

        }
        this.spinnerService.hide();
    }

    /**
     * Get teams list
     * @returns {Promise<any>}
     */
    private async getTeams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team"], {}, {
                data_structure: "select",
                category: "followup"
            });
        if (data) {
            this.teams = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get list of kinds
     * @returns {Promise<any>}
     */
    private async getKinds(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "kind"], {}, {
                data_structure: "select",
                category: "followup"
            });
        if (data) {
            this.kinds = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getServiceLevels(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put, ["order"], {});
        if (data) {
            this.serviceLevels = data.service_levels;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getRemarkTypes(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            this.remarkTypes = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getCountries(): Promise<any> {
        const data = await this.helperService.prepareCountriesList();
        if (data) {
            this.countries =
                data.sort((a: { value: string, name: string }, b: { value: string, name: string }): number => {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
            this.countriesFiltered = this.countries;
        }
    }

    private async getCustomers(): Promise<void> {
        this.spinnerService.show();
        const data = await this.apiService.request(Api.EMethod.Get, ["customer"], {}, {
            only_active: true
        });
        if (data) {
            this.customers = data.data;
            this.customersFiltered = this.customers;
        }
        this.changeDetectorRef.markForCheck();
        this.spinnerService.hide();
    }

    private async getCouriers(): Promise<any> {
        const data = await this.apiService.request(Api.EMethod.Get, ["courier_service"],
            {}, {
                data_structure: "select",
                service_level_ids: this.formGroup.controls["service_levels"].value
            });
        if (data) {
            this.couriersServiceLevel = data.data;
            this.couriersServiceLevelFiltered = this.couriersServiceLevel;
        }
    }

    private async getStatuses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["status"], {}, {
            data_structure: "select",
            type: "order"
        });
        if (data) {
            const taskParams = this.taskParams.find(tp => tp.event === "OrderStatusChanged");
            taskParams.params[0].options = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private addEventParams(event: string, params: any = []): void {
        this.currentEventParams = this.eventParams.find(ep => ep.event === event);
        let paramsControls = this.formGroup.get("params") as FormGroup;
        paramsControls.controls = {};
        if (!this.currentEventParams) {
            return;
        }
        this.currentEventParams.params.forEach(p => {
            let value = p.default_value;
            if (params && params[p.name]) {
                value = params[p.name];
            }
            let control = new FormControl(value);
            if (p.required) {
                control.setValidators([Validators.required]);
            }
            paramsControls.addControl(p.name, control);
        });
        this.formGroup.controls["params"] = paramsControls;
        this.changeDetectorRef.markForCheck();
    }

    private addTaskEventParams(taskForm: FormGroup, event: string, params: any = []): void {
        const taskParams = this.taskParams.find(ep => ep.event === event);
        let paramsControls = taskForm.get("params") as FormGroup;
        paramsControls.controls = {};
        if (!taskParams) {
            return;
        }
        taskParams.params.forEach(p => {
            let value = p.default_value;
            if (params && params[p.name]) {
                value = params[p.name];
            }
            let control = new FormControl(value);
            if (p.required) {
                control.setValidators([Validators.required]);
            }
            paramsControls.addControl(p.name, control);
        });
        taskForm.controls["params"] = paramsControls;
        this.changeDetectorRef.markForCheck();
    }

    private async store(): Promise<any> {
        this.spinnerService.show();

        const {code, message, data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post, ["auto-followups"],
            this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.router.navigate([
                this.state.section,
                this.state.component,
                "view",
                "id",
                data.id
            ]);
        }
    }

    private async update(): Promise<any> {
        this.spinnerService.show();
        const formValues = this.formGroup.value;
        if (formValues.event === "App\\Events\\order\\OrderCreated") {
            formValues.courier_services = [];
        }

        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["auto-followups", this.state.params.id], formValues);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    private setupListeners(): void {
        this.countriesSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.countriesFiltered = this.countries.filter((country: { name: string }): boolean =>
                        country.name.toLowerCase().indexOf(value) > -1);
                } else {
                    this.countriesFiltered = this.countries;
                }
            });
        this.formGroup.get("service_levels").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((values: number[]): void => {
                this.getCouriers();
            });
        this.formGroup.get("event").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((event: string): void => {
                if (event === "App\\Events\\order\\OrderCreated") {
                    this.formGroup.get("courier_services").setValue([]);
                }
                this.addEventParams(event);
            });
        this.customersSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                if (value) {
                    this.customersFiltered = this.customers.filter((customer: { name: string }): boolean =>
                        customer.name.toLowerCase().indexOf(value) > -1);
                } else {
                    this.customersFiltered = this.customers;
                }
            });
    }

    public addTask(): FormGroup {
        const taskForm = new FormGroup({
            name: new FormControl(null, [Validators.required]),
            comment: new FormControl(null),
            deadline_type: new FormControl("time"),
            deadline: new FormControl(null),
            send_email: new FormControl(false),
            send_email_deadline: new FormControl(0),
            event_close: new FormControl(null),
            params: new FormGroup({})
        });
        taskForm.get("event_close").valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((event: string): void => {
            this.addTaskEventParams(taskForm, event, taskForm.get("params")?.value);
        });
        (this.formGroup.get("tasks") as UntypedFormArray).push(taskForm);
        this.changeDetectorRef.markForCheck();
        AmplitudeService.eventClick("Add task (auto followups)");
        return taskForm;
    }

    public removeTask(index: number): void {
        (this.formGroup.get("tasks") as UntypedFormArray).removeAt(index);
        this.changeDetectorRef.markForCheck();
    }

    public getTaskParamsForEvent(task: FormGroup): Params[] | null {
        const event = task.get("event_close").value;
        return this.taskParams.find(tp => tp.event === event)?.params || null;
    }
      

    /**
     * Submit form (add)
     * @returns {Promise<any>}
     */
    public submit(): void {
        if (this.state.params.id) {
            this.update();
        } else {
            this.store();
        }
        AmplitudeService.eventClick("Submit (auto followups)");
        this.showList();
    }

    public async delete(): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this auto-followup?")) {
            return;
        }
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["auto-followups", this.state.params.id], this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.router.navigate([
                this.state.section,
                this.state.component
            ]);
        }
    }

    public showList(): void {
        this.router.navigate([
            this.state.section,
            this.state.component
        ]);
    }

    public ngOnInit(): void {
        this.getKinds();
        this.getTeams();
        this.getServiceLevels();
        this.getRemarkTypes();
        this.getCountries();
        this.getStatuses();
        this.getCustomers();
        if (this.state.params.id) {
            this.getData();
        }

        this.setupListeners();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "followups-auto",
            actions: {
                "view": ["edit_auto_followups"],
                "add": ["add_auto_followups"],
            }
        };
    }
}
