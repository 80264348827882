import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmComponent} from "../../../../../common/components/confirm/confirm.component";

interface NotesConditionalParameters {
    usage: string,
    formControlName: string,
    label: string
}

@Component({
    selector: "notes-form",
    templateUrl: "notes.component.html",
    styleUrls: [
        "notes.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class NotesFormComponent implements OnDestroy, OnChanges {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public notesForm: FormGroup = new FormGroup({
        notes: new FormArray([])
    });

    @Input()
    public data = [];

    @Input()
    public noteUsage = [];

    @Input()
    public conditionalParameters: NotesConditionalParameters[] = [];

    @Input()
    public showCancel = true;

    @Output()
    public onSave: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onCancel: EventEmitter<void> = new EventEmitter<void>();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    private prepareForm(): void {
        this.notes.clear();
        if (this.data?.length) {
            this.data.forEach(note => {
                let noteGroup = new FormGroup({
                    id: new FormControl(null),
                    content: new FormControl(null),
                    usage: new FormControl(null),
                    parameters: new FormGroup({})
                });

                this.conditionalParameters.forEach(cp => {
                    noteGroup.controls["parameters"].addControl(cp.formControlName, new FormControl());
                });

                noteGroup.patchValue(note);
                this.notes.push(noteGroup);
            });
        } else {
            this.addNote();
        }

        this.changeDetectorRef.markForCheck();
    }

    private updateVisibility(noteFormGroup: FormGroup, usages: string[]): void {
        const parametersControl = noteFormGroup.get("parameters") as FormGroup;
        usages.forEach(usage => {
            const parameter = this.conditionalParameters.find(cp => {
                return cp.usage == usage;
            });
            if (parameter) {
              parametersControl.get(parameter.formControlName).enable();
            } else {
              parametersControl.get(parameter.formControlName).disable();
            }
        });
    }
    
    public isUsageIncluded(usage: any, value: string): boolean {
        return usage?.includes(value);
    }

    public get notes(): FormArray {
        return this.notesForm.get("notes") as FormArray;
    }    
    
    public addNote(): void {
        const noteFormGroup = new FormGroup({
            id: new FormControl(null),
            content: new FormControl(null, [Validators.required]),
            usage: new FormControl(null, [Validators.required]),
            parameters: new FormGroup({})
        });

        this.conditionalParameters.forEach(cp => {
            noteFormGroup.controls["parameters"].addControl(cp.formControlName, new FormControl());
        });

        noteFormGroup.get("usage")?.valueChanges
            .subscribe(value => {
                this.updateVisibility(noteFormGroup, value);
            });

        this.notes.push(noteFormGroup);
    }

    public async handleSaveNote(index: number): Promise<void> {
        const noteGroup = this.notes.at(index);
        if (noteGroup.invalid) {
            return;
        }
        this.onSave.emit(noteGroup.value);
        noteGroup.markAsPristine();
        this.changeDetectorRef.markForCheck();
    }

    public async handleRemoveNote(index: number): Promise<void> {
        const noteGroup = this.notes.at(index);
        if (noteGroup.get("id").value && noteGroup.invalid) {
            return;
        }
        this.notes.removeAt(index);

        if (!this.notes.length) {
            this.addNote();
        }

        if (noteGroup.get("id").value) {
            this.onDelete.emit(noteGroup.value);
        }
        this.changeDetectorRef.markForCheck();
    }

    public cancel(): void {
        this.onCancel.emit();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.prepareForm();
        this.changeDetectorRef.markForCheck();
    }

    protected readonly Validators = Validators;
}
